var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "" } },
    [
      _c(
        "v-card",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
          staticStyle: { overflow: "hidden" },
          attrs: { color: "fill" },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "11" } },
                [
                  _c("v-card-title", { class: _vm.title }, [
                    _vm._v(_vm._s(_vm.selectedAd.ad_name) + " Ad Planner"),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-col",
                { staticClass: "mr-0", attrs: { dense: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        loading: _vm.loadingFile,
                        disabled: _vm.loadingFile,
                        text: "",
                        icon: "",
                      },
                      on: { click: _vm.download },
                    },
                    [_c("v-icon", [_vm._v("mdi-file-download-outline")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "mr-0 pr-4", attrs: { dense: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-2",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:value", false)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c("v-data-table", {
            staticClass: "mb-4",
            staticStyle: { cursor: "pointer" },
            attrs: {
              headers: _vm.headers,
              height: _vm.tableHeight,
              items: _vm.items,
              "group-by": "pagePos",
              "no-data-text": "No Data",
              "loading-text": "Loading...",
              loading: _vm.loading,
              dense: "",
              "fixed-header": "",
              "hide-default-footer": "",
              "items-per-page": _vm.pageSize,
              expanded: _vm.expanded,
            },
            on: {
              "update:items": function ($event) {
                _vm.items = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `group.header`,
                  fn: function ({ group, headers, toggle, isOpen, items }) {
                    return [
                      _c(
                        "td",
                        { attrs: { colspan: headers.length } },
                        [
                          _c(
                            "v-btn",
                            {
                              ref: group,
                              attrs: {
                                dense: "",
                                small: "",
                                icon: "",
                                "data-open": isOpen,
                              },
                              on: { click: toggle },
                            },
                            [
                              isOpen
                                ? _c("v-icon", [_vm._v("mdi-chevron-up")])
                                : _c("v-icon", [_vm._v("mdi-chevron-down")]),
                            ],
                            1
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                items[0].page_name +
                                  " : " +
                                  items[0].position_name
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: `item.case_cost`,
                  fn: function ({ item }) {
                    return [
                      item.case_cost
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.case_cost)
                                )
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.amap`,
                  fn: function ({ item }) {
                    return [
                      item.amap
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(_vm.formatCurrencyDisplay(item.amap)) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.early_buy_billback`,
                  fn: function ({ item }) {
                    return [
                      item.early_buy_billback
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    item.early_buy_billback
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.ad_billback`,
                  fn: function ({ item }) {
                    return [
                      item.ad_billback
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.ad_billback)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.edlc_billback`,
                  fn: function ({ item }) {
                    return [
                      item.edlc_billback
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.edlc_billback)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.tpr_billback`,
                  fn: function ({ item }) {
                    return [
                      item.tpr_billback
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.tpr_billback)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.ad_scan`,
                  fn: function ({ item }) {
                    return [
                      item.ad_scan
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.ad_scan)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.edlc_scan`,
                  fn: function ({ item }) {
                    return [
                      item.edlc_scan
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.edlc_scan)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.tpr_scan`,
                  fn: function ({ item }) {
                    return [
                      item.tpr_scan
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.tpr_scan)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.epay_tpr_scan`,
                  fn: function ({ item }) {
                    return [
                      item.epay_tpr_scan
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.epay_tpr_scan)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.epay_ad_scan`,
                  fn: function ({ item }) {
                    return [
                      item.epay_ad_scan
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.epay_ad_scan)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.ad_scan_compete`,
                  fn: function ({ item }) {
                    return [
                      item.ad_scan_compete
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    item.ad_scan_compete
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.regular_retail`,
                  fn: function ({ item }) {
                    return [
                      item.regular_retail
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.regular_retail)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.unit_cost`,
                  fn: function ({ item }) {
                    return [
                      item.unit_cost
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.net_unit_cost)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.ad_retail`,
                  fn: function ({ item }) {
                    return [
                      item.ad_retail
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.ad_retail)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.qty_per_case`,
                  fn: function ({ item }) {
                    return [
                      item.qty_per_case
                        ? _c("span", [
                            _vm._v(" " + _vm._s(item.qty_per_case) + " "),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.last_90_days_shipped_qty`,
                  fn: function ({ item }) {
                    return [
                      item.last_90_days_shipped_qty
                        ? _c("span", [
                            _vm._v(
                              " " + _vm._s(item.last_90_days_shipped_qty) + " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.ad_gross_profit_percent`,
                  fn: function ({ item }) {
                    return [
                      item.ad_gross_profit_percent
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    item.ad_gross_profit_percent
                                  )
                                ) +
                                "% "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: `item.components`,
                  fn: function ({ item }) {
                    return [
                      item.is_shipper
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  ;(_vm.selectedItem = item),
                                                    (_vm.showComponents = true)
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-view-list"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Components")])]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c(
            "v-toolbar",
            {
              staticClass: "mt-0",
              attrs: { dense: "", flat: "", color: "#fafafa" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "mt-0 pt-0",
                  attrs: { align: "center", justify: "end" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "max-width": "150px" },
                      attrs: { cols: "2" },
                    },
                    [
                      _c("v-select", {
                        staticClass: "mt-5",
                        attrs: {
                          dense: "",
                          items: _vm.pageSizes,
                          label: "Items Per Page",
                          select: "",
                          "menu-props": {
                            top: true,
                            offsetY: true,
                            maxHeight: 500,
                          },
                        },
                        on: {
                          change: function ($event) {
                            return _vm.getItems()
                          },
                        },
                        model: {
                          value: _vm.pageSize,
                          callback: function ($$v) {
                            _vm.pageSize = $$v
                          },
                          expression: "pageSize",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        icon: "",
                        dense: "",
                        disabled: _vm.disablePrevious,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.previous()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                    1
                  ),
                  _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: { icon: "", dense: "", disabled: _vm.disableNext },
                      on: {
                        click: function ($event) {
                          return _vm.next()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("ComponentItems", {
            attrs: {
              value: _vm.showComponents,
              selectedItem: _vm.selectedItem,
              selectedAd: _vm.selectedAd,
            },
            on: {
              "update:value": function ($event) {
                _vm.showComponents = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }